import { Button, Calendar, Card, Checkbox, Col, Divider, Layout, message, Modal, Row, Select, Skeleton, Space, Spin, Tag, Typography } from 'antd';
import { LeftOutlined, InfoCircleOutlined, EnvironmentOutlined, LoadingOutlined, StopOutlined, InfoCircleFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useParams, withRouter } from 'react-router-dom';
import moment from 'moment';
import { http } from '../../helpers/http';
import { LessonsList } from './MobileCalendar';
import { ReactComponent as GoBackArrow } from '../../img/ic-goback.svg';
// @ts-ignore
import _ from 'lodash';
import { InstructorInfoCard } from './InstructorInfoCard';
import { DrivingNotification } from './DrivingNotification';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { BonusAdjustForm } from '../Payment/BonusAdjustForm';

const { Text } = Typography;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

moment.updateLocale('lv', {
    weekdaysMin: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
    week: {
        dow: 1
    },
    months: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
    monthsShort: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
});

const styles = {
    amountText: {
        fontSize: 17,
        fontWeight: 700,
    },
    numberInputBtn: {
        width: 38,
        height: 38,
        fontSize: 22,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#079DC1",
    }
}

const drivingOptions = {
    "field": "Laukums",
    "city": "Satiksme"
} as any

function LessonSlotLine(props: any) {
    const { t } = useTranslation();
    const { instructor, lessons, testDrive } = props;
    const [isOpen, setOpen] = useState(false);
    const [signingUp, setSigningUp] = useState(false);
    const [error, setError] = useState("");
    const [enoughFunds, setEnoughFunds] = useState(false);
    const s = props.slot;
    const price = s.total_rate
    const selfReserved = lessons?.some((lesson: any) => lesson.lesson_id === s.lesson_id)
    const [bonusAmount, setBonusAmount] = useState(
        Number(s.discounted_rate) > 0
            ? Number(props.balance.current_credit) < Number(price)
                ? (Number(s.discounted_rate) <= Number(props.balance.current_credit)
                    && (Number(props.balance.bonus_balance) + Number(s.discounted_rate) >= Number(price)))
                    ? (Number(price) - Number(props.balance.current_credit)).toFixed(2)
                    : "0"
                : "0"
            : "0"
    );

    const contentStyle: any = {
        margin: 2,
        borderRadius: 6,
        padding: 0,
        fontSize: 12,
        border: "1px solid transparent",
        paddingLeft: 4,
        paddingRight: 4
    };
    contentStyle.backgroundColor = s.lesson_id ? selfReserved ? "#d7ffd7" : "#ffdbe7" : "transparent";
    contentStyle.color = "#666";
    contentStyle.border = "1px solid #aaa";

    const content = <div
        title={`${s.date}
    ${s.category_letter} ${t('common.category')}
    ${s.city}
    ${s.comment ? s.comment : ""}`}
        style={contentStyle}
        onClick={() => { s.lesson_id ? setOpen(false) : setOpen(true); }}
    >
        <span>
            {/* <Button onClick={() => console.log(s)}>~</Button> */}
            {s.time_from} - {s.time_to} {s.categort_letter} {s.city} {s.lesson_id ? selfReserved ? `(${t("driving.reserved_for_you")})` : `(${t("driving.taken_slot")})` : price + " EUR"}
            {s.comment ? <InfoCircleOutlined style={{ marginLeft: 10 }} /> : <></>}
            <b> {s?.driving_option ? drivingOptions[s?.driving_option] : ""}</b>
        </span>
    </div>;

    function signup() {
        const date = moment(s.date, "YYYY-MM-DD")
        const time = moment(s.time_from, "HH:mm:ss")
        if (date.isSame(moment(), "day")) {
            if (time.isSameOrAfter(moment().add(15, "minutes"), "minutes")) {
                setSigningUp(true);
                if (!signingUp) {
                    http.post(`lessons/${s.slot_id}`, { bonus_amount: Number(bonusAmount) > 0 ? Number(bonusAmount) : undefined }).then((res: AxiosResponse) => {
                        if (res?.data?.success === 0) {
                            dayLimitErrorResolver(res.data.data)
                            return;
                        }
                        if (props.isFirst) {
                            if (!!s.exam) window.location.href = '/planner';
                            props.refreshBalance();
                            message.success(t('driving.you_are_succesfully_signed_up'))
                        } else {
                            setOpen(false);
                            props.removeTimeSlot(s?.slot_id);
                            if (!!s.exam) window.location.href = '/planner';
                            props.refreshBalance();
                            message.success(t('driving.you_are_succesfully_signed_up'))
                        }
                    }).catch((err: AxiosError) => {
                        errorMessageResolver(err);
                        message.error(t('calendar.lesson_is_taken'))
                    });
                }
            } else {
                message.error(t('calendar.15min_before_lesson'))
            }
        } else {
            setSigningUp(true);
            if (!signingUp) {
                http.post(`lessons/${s.slot_id}`, { bonus_amount: Number(bonusAmount) > 0 ? Number(bonusAmount) : undefined }).then((res: AxiosResponse) => {
                    if (res?.data?.success === 0) {
                        dayLimitErrorResolver(res.data.data)
                        return;
                    }
                    if (props?.isFirst) {
                        if (!!s.exam) window.location.href = '/planner';
                        props.refreshBalance();
                        message.success(t('driving.you_are_succesfully_signed_up'))
                    } else {
                        setOpen(false);
                        props.removeTimeSlot(s?.slot_id);
                        if (!!s.exam) window.location.href = '/planner';
                        props.refreshBalance();
                        message.success(t('driving.you_are_succesfully_signed_up'))
                    }
                }).catch((err: AxiosError) => {
                    errorMessageResolver(err);
                    message.error(t('calendar.lesson_is_taken'))
                });
            }
        }
    }

    const errorMessageResolver = (err: any) => {
        if (!!!err.response?.data) return;
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                setError(`${t(`errors.${data[0].message_type}`)} ${data[0].info} ${t(`errors.error_activities.${data[0].reason}`)}`);
            }
        } else {
            setError("")
        }
    }

    const dayLimitErrorResolver = (data: any) => {
        if (data === "daily_driving_limit_reached") {
            message.error(t(`errors.error_activities.${data}`))
        } else {
            message.error(t(`errors.error`));
        }
        setSigningUp(false);
    }

    useEffect(() => {
        setEnoughFunds(
            (Number(s.discounted_rate) <= Number(props.balance.balance)
                && Number(props.balance.bonus_balance) + Number(props.balance.balance) >= Number(price))
            || (Number(s.discounted_rate) <= Number(props.balance.current_credit)
                && Number(props.balance.bonus_balance) + Number(props.balance.current_credit) >= Number(price))
        );
    }, [props.balance]);

    // useEffect(() => {
    //     console.log(bonusAmount,
    //         Number(s.discounted_rate) > 0
    //         , Number(props.balance.current_credit) < Number(price)
    //         , Number(s.discounted_rate) <= Number(props.balance.current_credit)
    //         , (Number(props.balance.bonus_balance) + Number(s.discounted_rate) >= Number(price))
    //         , (Number(price) - Number(props.balance.current_credit)).toFixed(2)
    //     )
    // }, [bonusAmount])

    // console.log(instructor, price, props.balance)
    return <div>
        <Modal
            onCancel={() => { setOpen(false) }}
            width={600}
            // onOk={signup}
            visible={isOpen}
            // title={s.category_letter +
            //     ` ${t('common.category')}, ` +
            //     (s.transmission === "auto" ? t('common.transmission.auto') : t('common.transmission.manual')) +
            //     ` ${t('common.transmission.transmission')}, ` + (instructor.languages ? instructor.languages?.map((lang: any) => " " + lang.value) : "")
            // }
            title={
                s.exam
                    ? <div>
                        <h2>
                            {t('calendar.signup_for_school_driving_exam')}
                        </h2>
                    </div>
                    : <div>
                        <h2>
                            {testDrive ? t("driving.eval.reserve_test_drive") : t('driving.signup_driving_lesson_to')}
                        </h2>
                        <h2>
                            {instructor.first_name} {instructor.last_name}
                        </h2>
                    </div>
            }
            footer={
                enoughFunds
                    ? <>
                        <Button onClick={() => { setOpen(false) }}>{t('common.cancel')}</Button>
                        <Button type="primary" onClick={signup}>{t('common.signup')}</Button>
                    </>
                    : <></>
            }
        >
            <Row style={{ marginBottom: 24 }}>
                <div style={{ paddingRight: 8, marginRight: 8, borderRight: "1px solid #667380" }}>
                    {s.category_letter} {t('common.category')}
                </div>
                <div style={{ paddingRight: 8, marginRight: 8, borderRight: "1px solid #667380" }}>
                    {s.transmission === "auto" ? t('common.transmission.auto') : t('common.transmission.manual')} {t('common.transmission.transmission')}
                </div>
                <div style={{ paddingRight: 8, marginRight: 8, borderRight: "1px solid #667380" }}>
                    {instructor.languages ? instructor.languages?.map((lang: any) => " " + lang.value) : ""}
                </div>
                {
                    s.driving_option
                    && <div style={{ paddingRight: 8, marginRight: 8, borderRight: "1px solid #667380" }}>
                        {s.driving_option ? drivingOptions[s.driving_option] : ""}
                    </div>
                }
            </Row>
            <Row>
                <Col span={24} lg={12}>
                    <p style={{ marginBottom: 0 }}>{t('driving.chosen_lesson_time')}:</p>
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>{moment(s.date).format("Do MMMM YYYY")}, {moment(s.time_from, "HH:mm:ss").format("HH:mm")} - {moment(s.time_to, "HH:mm:ss").format("HH:mm")}</p>
                </Col>
                <Col span={24} lg={12}>
                    <p style={{ marginBottom: 0 }}>{t('instructor_card.meeting_place')}:</p>
                    <p style={{ fontWeight: "bold", marginBottom: 0 }}>{s.meeting_place}, {s.city}</p>
                </Col>
            </Row>
            <Divider />
            <p style={{ marginBottom: 0 }}>{t('driving.price_for_lesson')}:</p>
            <h3>
                {
                    Number(bonusAmount) > 0
                    && <s style={{ ...styles.amountText, marginRight: 10 }}>{Number(price).toFixed(2)}</s>
                }
                <span style={{ ...styles.amountText, color: "#079DC1" }}>{(Number(price) - Number(bonusAmount)).toFixed(2)} EUR</span>
            </h3>
            {
                s.discount_max_amount > 0
                && <BonusAdjustForm
                    amount={Number(price)}
                    student={props.self}
                    bonusAmount={bonusAmount}
                    setBonusAmount={setBonusAmount}
                    type="bonus_driving"
                    externalSetting={{
                        maxPercentage: s.discount_max_amount,
                        maxDiscount: Number(price) - Number(s.discounted_rate),
                        hasDiscount: true,
                    }}
                />
            }
            <Divider />
            <Row style={{ marginBottom: 20 }}>
                {
                    enoughFunds
                        ? <></>
                        : <Col span={12} style={{ marginBottom: 15 }}>
                            <Tag color="rgba(230, 129, 46, 0.10)" style={{ marginTop: 15, whiteSpace: "normal", color: "#E6812E", borderColor: "#E6812E" }}><InfoCircleFilled style={{ color: "#E6812E", marginRight: 4 }} />{t('common.not_enough_balance')}</Tag>
                            <Link to="/planner/payment-help"><Button type="primary">{t('calendar.replenish')}</Button></Link>
                        </Col>
                }
                {
                    props.cancelPercent > 0
                    && <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%", color: "#E6812E", borderColor: "#E6812E" }} color="rgba(230, 129, 46, 0.10)">
                        <InfoCircleFilled style={{ color: "#E6812E", marginRight: 4 }} />{t('driving.warning.comission_warning_1')} {props.cancelPercent} % {t('driving.warning.comission_warning_2')}
                    </Tag>
                }
                <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%", color: "#E6812E", borderColor: "#E6812E" }} color="rgba(230, 129, 46, 0.10)">
                    <InfoCircleFilled style={{ color: "#E6812E", marginRight: 4 }} />{t('driving.warning.24h_cancel_lesson')}
                </Tag>
                {
                    props.self?.transmission !== s?.transmission
                        ? props.self?.transmission?.length > 0
                            ? <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%", color: "#E6812E", borderColor: "#E6812E" }} color="rgba(230, 129, 46, 0.10)">
                                <InfoCircleFilled style={{ color: "#E6812E", marginRight: 4 }} />{t('driving.warning.transmission_warning', { transmission: props.self?.transmission === "manual" ? t('common.transmission.manual') : t('common.transmission.auto') })}
                                {/* Atgādinam, ka jūsu pieteikumā ir norādīta {props.self.transmission === "manual" ? "manuālā" : "automātiskā"} pārnesumkārba. Tad nozīmē ka CSDD eksāmens būs jākārto ar šo pārnesumkārbas veidu. */}
                            </Tag>
                            : ""
                        : ""
                }
            </Row>
            {
                s?.comment
                    ? <>
                        <Divider />
                        <Space>
                            <InfoCircleOutlined />
                            <Text>{s.comment}</Text>
                        </Space>
                    </>
                    : <></>
            }
            {
                error.length > 0
                && <Tag color="rgba(230, 129, 46, 0.10)" style={{ marginBottom: 10, whiteSpace: "normal", width: "100%", textAlign: "center", color: "#E6812E", borderColor: "#E6812E" }}>
                    <InfoCircleFilled style={{ color: "#E6812E", marginRight: 4 }} />{error}
                </Tag>
            }
        </Modal>
        {/*
 <Modal
            onCancel={() => { setOpen(false) }}
            onOk={signup}
            // visible={isOpen}
            title={s.category_letter +
                ` ${t('common.category')}, ` +
                (s.transmission === "auto" ? t('common.transmission.auto') : t('common.transmission.manual')) +
                ` ${t('common.transmission.transmission')}, ` + (instructor.languages ? instructor.languages?.map((lang: any) => " " + lang.value) : "")
            }
            footer={
                Number(price) <= Number(props.balance.balance)
                    ? <>
                        <Button onClick={() => { setOpen(false) }}>{t('common.cancel')}</Button>
                        <Button type="primary" onClick={signup}>{t('common.signup')}</Button>
                    </>
                    : Number(price) <= Number(props.balance.current_credit)
                        ? <>
                            <Button onClick={() => { setOpen(false) }}>{t('common.cancel')}</Button>
                            <Button type="primary" onClick={signup}>{t('common.signup')}</Button>
                        </>
                        : <></>
            }
        >
            <Row>
                {
                    Number(price) <= Number(props.balance.balance)
                        ? <Col span={12}>
                            {t('calendar.signup_for_driving')}
                        </Col>
                        : Number(price) <= Number(props.balance.current_credit)
                            ? <Col span={12}>
                                {t('calendar.signup_for_driving')}
                            </Col>
                            : <Col span={12}>
                                <Tag color="red">{t('common.not_enough_balance')}</Tag>
                                <Link to="/planner/payment-help"><Button type="primary">{t('calendar.replenish')}</Button></Link>
                            </Col>
                }

                <Col span={12}>
                    <Space>
                        <h3>{t('common.price')}:</h3>
                        <h3>{price} EUR</h3>
                    </Space>
                </Col>
                {
                    props.cancelPercent > 0
                    && <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%" }} color="warning">
                        {t('driving.warning.comission_warning_1')} {props.cancelPercent} % {t('driving.warning.comission_warning_2')}
                    </Tag>
                }
                <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%" }} color="warning">
                    {t('driving.warning.24h_cancel_lesson')}
                </Tag>
                {
                    props.self?.transmission !== s?.transmission
                        ? props.self?.transmission?.length > 0
                            ? <Tag style={{ marginBottom: 5, whiteSpace: "normal", textAlign: "center", width: "100%" }} color="red">
                                {t('driving.warning.transmission_warning', { transmission: props.self?.transmission === "manual" ? "manuālā" : "automātiskā" })}
                            </Tag>
                            : ""
                        : ""
                }
            </Row>
            <Divider />
            <Space>
                <span><EnvironmentOutlined /></span>
                <span>{s.meeting_place}, {s.city}</span>
            </Space>
            {
                s?.comment
                    ? <>
                        <Divider />
                        <Space>
                            <InfoCircleOutlined />
                            <Text>{s.comment}</Text>
                        </Space>
                    </>
                    : <></>
            }
            <Divider />
            <br />{s.time_from} - {s.time_to}
            <br />{s.date}
            <br />
            {
                error.length > 0
                && <Tag color="red" style={{ marginBottom: 10, whiteSpace: "normal", width: "100%", textAlign: "center" }}>
                    {error}
                </Tag>
            }
        </Modal>
        */}
        {content}
    </div>;
}

function DateCell(props: any) {
    const date = props.date.format(dateFormat);

    const d = props.timeSlots.filter((d: any) => {
        return d.date === date && moment(d.date, "YYYY-MM-DD").isSameOrAfter(moment(props?.self?.can_start_driving_date ?? undefined), 'day');
    });

    return <div>
        {d.map((s: any) => <LessonSlotLine
            testDrive={props.testDrive}
            slot={s}
            instructor={props.instructor}
            key={`hujak-id-${s.slot_id}`}
            isFirst={props.isFirst}
            removeTimeSlot={(id: any) => props.removeTimeSlot(id)}
            // insufficientBalance={props.insufficientBalance}
            balance={props.balance}
            cancelPercent={props.cancelPercent}
            refreshBalance={() => props.refreshBalance()}
            self={props.self}
            studentCanDrive={props.studentCanDrive}
            lessons={props.lessons}
        />)}
    </div>;
}

export const CalendarView = withRouter((props: any) => {
    const { t } = useTranslation();
    const { isFirst, exam, self, lessons, testDrive } = props;
    const history = useHistory();
    const { city_id } = history.location.state as any || self.city_id;
    const city = props.city
    const { instructor_id } = useParams() as any;
    const [timeSlots, setTimeSlots] = useState(null) as any;
    const [service, setService] = useState({} as any);
    const [instructor, setInstructor] = useState({} as any);
    const [classifiers, setClassifiers] = useState({
        languages: [],
        cities: []
    }) as any;
    const [balance, setBalance] = useState({} as any);
    const [filters, setFilters] = useState(
        {
            city: city || city_id || self?.city_id,
            language: "",
            transmission: "",
            all: false,
        }
    )
    const [allCitiesSpin, setAllCitiesSpin] = useState(false);
    const [calendarState, setCalendarState] = useState([moment()] as any);
    const [mobile, setMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState([] as any);
    const [studentCanceLPercent, setStudentCancelPercent] = useState(0 as number);
    const [studentCanDrive, setStudentCanDrive] = useState(true);
    const [examTransmission, setExamTransmission] = useState(null as null | "manual" | "auto")
    const [freeSlotsOnly, setFreeSlotsOnly] = useState(false);
    const [isTestDrive, setIsTestDrive] = useState(false);

    const checkForOtherRestrictions = async () => {
        const allowed = await http.get(`allowed-to-drive`).then((response: any) => {
            if (!response.data.allowed) {
                message.error(t(`errors.${response.data.reason ?? "error"}`))
                history.push('/planner')
                return false;
            } else {
                return true;
            }
        });
        return allowed;
    }

    const fetchClassifiers = async () => {
        const languages = await http.get("/languages");
        const cities = await http.get("/cities");
        setClassifiers((data: any) => {
            return {
                ...data,
                languages: languages.data.data,
                cities: cities.data.data
            }
        })
    }

    const studentPaidDrivingRegistration = () => {
        http.post(`check-individual-driving-registration`).then((response: any) => {
            //console.log(response)
            const group = self.only_study_group.find((group: any) => {
                return group.category_id === self.category_id
            });
            const fakeGroup = group === undefined || group.id === Number(process.env.REACT_APP_NO_GROUP_ID)
            setStudentCanDrive(!!response.data.paid || !fakeGroup)
        })
    }

    const filterTimeslotByDate = (slots: []) => {
        return slots.filter((slot: any) => {
            const slotTime = slot.date.concat(" " + slot.time_from)
            const date = moment(slot.date, "YYYY-MM-DD")
            const time = moment(slot.time_from, "HH:mm:ss")
            if (date.isSame(moment(), "day")) {
                return time.isSameOrAfter(moment().add(15, "minutes"), "minutes")
            }
            return date.isSameOrAfter(moment(), 'day');
        })
    }

    const fetchNotifications = async () => {
        await http.get(`/notifications-unread-driving`).then((response: any) => {
            //console.log(response)
            setNotification(response.data)
        })
    }

    const fetchStudentCancelPercent = async () => {
        await http.get('/get-student-cancel-school-percent').then((response: any) => {
            setStudentCancelPercent(response.data.data)
        })
    }

    async function asyncEffect(exam: boolean, filters: any, period: any, mobile: boolean = false, transmission: null | "auto" | "manual" = examTransmission) {
        setLoading(true)
        let timeSlots;
        const balance = (await http.get("balance")).data.data;
        //console.log(balance)
        setBalance(balance)
        const allowed = checkForOtherRestrictions();
        if (!allowed) return;
        if (exam || testDrive) {
            const defaultParams = {
                language: filters.language,
                city: filters.city,
                transmission: filters.transmission,
                test_drive: testDrive ? 1 : undefined,
                test_category: testDrive ? self.category_id : undefined
            }
            if (testDrive) {

            }
            const examSlots = await http.get(`calendar-time-slots?system=1`,
                {
                    params: mobile
                        ? {
                            student_from: moment(period[0]).format("YYYY-MM-DD"),
                            student_to: moment(period[1]).format("YYYY-MM-DD"),
                            ...defaultParams
                        }
                        : {
                            student_year: moment(period[0]).format("YYYY"),
                            student_month: moment(period[0]).format("MM"),
                            ...defaultParams
                        }
                }
            )
            const sortedLessons = _.sortBy(examSlots.data.data, (lesson: any) => moment(lesson.time_from, "HH:mm:ss"))
            setTimeSlots(filterTimeslotByDate(sortedLessons));
            return;
        }

        const studentResult = await http.get('self').then((response: any) => {
            const hasExam = http.get(`/student-progress/has-exam/${response.data.data.id}`).then((resp: any) => {
                return {
                    ...response.data.data,
                    hasExam: resp.data,
                };
            });
            return hasExam;
        });

        const student = studentResult;

        const result = filters.all
            ? await http.get(`time_slots/${instructor_id}/all?system=1`, {
                params: mobile
                    ? {
                        student_from: moment(period[0]).format("YYYY-MM-DD"),
                        student_to: moment(period[1]).format("YYYY-MM-DD")
                    }
                    : {
                        student_year: moment(period[0]).format("YYYY"),
                        student_month: moment(period[0]).format("MM")
                    }
            }).then((response: any) => {
                setAllCitiesSpin(false);
                setLoading(false);
                return response;
            })
            : await http.get(`time_slots/${instructor_id}?system=1`, {
                params: mobile
                    ? {
                        // city: city?.id,
                        city: city_id || city,
                        student_from: moment(period[0]).format("YYYY-MM-DD"),
                        student_to: moment(period[1]).format("YYYY-MM-DD")
                    }
                    : {
                        // city: city?.id,
                        city: city_id || city,
                        student_year: moment(period[0]).format("YYYY"),
                        student_month: moment(period[0]).format("MM")
                    }
            }).then((response: any) => {
                setAllCitiesSpin(false);

                return response;
            })

        await http.get(`/get-instructor-service/${instructor_id}`).then((response: any) => {
            if (!response.data.data.web) history.push('/planner/select-instructor')
            setService(response.data.data)
            setInstructor(response.data.data.instructor)
            setIsTestDrive(
                !!response.data.data.instructor.services
                    .find((service: any) => service.id === response.data.data.id)?.category?.test_drive
            )
        })

        const sortedLessons = _.sortBy(result.data.data, (lesson: any) => moment(lesson.time_from, "HH:mm:ss"))
        //console.log(sortedLessons)
        setTimeSlots(filterTimeslotByDate(sortedLessons));
    }

    const updateSlots = (date: any, mobile: boolean = false) => {
        if (mobile) {
            asyncEffect(exam, filters, date, true).then(() => setLoading(false));
            setCalendarState(date);
        } else if (!calendarState[0].isSame(date[0], "month")) {
            asyncEffect(exam, filters, date).then(() => setLoading(false));
            setCalendarState(date);
        }
    }

    const setTransmission = () => {
        let transmissionSelected = examTransmission;
        let city = filters.city;
        Modal.confirm({
            title: <>{t(`common.transmission.transmission`)}, {t(`common.city`)}</>,
            content: <div>
                <Select
                    style={{ width: "100%", marginBottom: 10 }}
                    onChange={(e: | "manual" | "auto") => {
                        // setExamTransmission(e);
                        transmissionSelected = e;
                    }}
                    placeholder={t(`common.transmission.transmission`)}
                    // value={examTransmission === null ? undefined : examTransmission}
                    defaultValue={examTransmission === null ? undefined : examTransmission}
                >
                    <Select.Option value="manual">
                        {t(`common.transmission.manual`)}
                    </Select.Option>
                    <Select.Option value="auto">
                        {t(`common.transmission.auto`)}
                    </Select.Option>
                </Select>
                <Select
                    // value={city === "" ? undefined : city}
                    defaultValue={city === "" ? undefined : city}
                    style={{ width: "100%" }}
                    onChange={e => city = e}
                    placeholder={t('common.city')}
                >
                    {classifiers.cities.filter((city: any) => {
                        return city.has_exam;
                    }).map((city: any) => {
                        return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                    })}
                </Select>
            </div>,
            onOk: () => {
                if (transmissionSelected && city !== "" && city !== undefined) {
                    setFilters({ ...filters, city, transmission: transmissionSelected });
                } else {
                    message.error("Lūdzu izvēlieties pārnesumkārbu un pilsētu!")
                    setTransmission();
                }
            },
            onCancel: () => {
                if (transmissionSelected) return;
                message.error("Lūdzu izvēlieties pārnesumkārbu!")
                setTransmission();
            }
        })
    }

    useEffect(() => {
        if (self !== undefined) {
            {
                if (exam) {
                    setStudentCanDrive((!!self?.got_theory_exam_finished && exam) || (exam && !!self?.only_driving))
                }
            }
        }
    }, [self])

    useEffect(() => {
        fetchClassifiers();
        setMobile(window.matchMedia("(max-width: 767px)").matches)
        fetchNotifications();
        fetchStudentCancelPercent();
        const activeGroup = self?.studygroups.find((el: any) => !!el.active)
        if (!!self?.only_driving) {
            studentPaidDrivingRegistration();
        }
    }, []);

    useEffect(() => {
    }, [calendarState]);

    useEffect(() => {
        if ((exam && filters.transmission === "" && classifiers?.cities?.length > 0) || (testDrive && filters.transmission === "" && classifiers?.cities?.length > 0)) {
            setTransmission();
            return
        }
        asyncEffect(exam, filters, calendarState, mobile).then(() => setLoading(false));
    }, [filters, classifiers])

    function removeTimeSlot(id: any) {
        //console.log(id)
        if (timeSlots) {
            //console.log(id)
            setTimeSlots(timeSlots.filter((timeSlot: any) => timeSlot.slot_id !== id));
        }
    }

    function renderDateCell(date: any) {
        return <DateCell
            testDrive={isTestDrive}
            isFirst={isFirst}
            instructor={instructor}
            date={date}
            removeTimeSlot={(id: any) => removeTimeSlot(id)}
            timeSlots={freeSlotsOnly
                ? timeSlots.filter((slot: any) => {
                    return lessons?.some((lesson: any) => lesson.lesson_id === slot.lesson_id) || !slot.lesson_id
                }) || []
                : timeSlots || []}
            // insufficientBalance={insufficientBalance}
            balance={balance}
            cancelPercent={studentCanceLPercent}
            refreshBalance={() => props.refreshBalance()}
            self={props.self}
            studentCanDrive={studentCanDrive}
            lessons={lessons}
        />
    }

    // if (insufficientBalance) {
    //     return <PaymentHelp />
    // }

    const citySelectHandler = (value: any) => {
        setFilters((data: any) => {
            return {
                ...data,
                city: value,
            }
        })
    }

    const languageSelectHandler = (value: any) => {
        setFilters((data: any) => {
            return {
                ...data,
                language: value,
            }
        })
    }

    const transmissionSelectHandler = (value: any) => {
        setFilters((data: any) => {
            return {
                ...data,
                transmission: value,
            }
        })
    }

    const eraseFilters = () => {
        setFilters((data: any) => {
            return {
                city: "",
                language: "",
                transmission: "",
                all: false,
            }
        })
    }

    const showAllCities = () => {
        setAllCitiesSpin(true);
        setFilters((data: any) => {
            return {
                ...data,
                all: !data.all
            }
        })
    }

    return (
        <Layout style={{ minHeight: "100vh" }} className="main-content">
            <Row
                className='header-row'
                style={{
                    background: "#FFF",
                    borderBottom: "2px solid #e7e7e7",
                    flexWrap: "wrap"
                }}
            >
                <Button
                    onClick={() => history.goBack()}
                    style={{ fontWeight: "bold", marginRight: 29 }}
                    icon={<GoBackArrow />}
                >
                </Button>
                {/* <Link to="/planner/select-instructor">
                    <Button type="text" style={{ padding: 0 }}> */}
                <b style={{ padding: "5px 29px", borderLeft: "1px solid #D9D9D9" }}>{t('calendar.choose_lesson')}</b>
                {/* </Button>
                </Link> */}
                {
                    !exam && timeSlots
                        ? <></>
                        : timeSlots ? <b>{t('calendar.signup_for_school_driving_exam')}</b> : <></>
                }
            </Row>
            <Layout.Content>
                <Row gutter={[24, 24]}>
                    {
                        !testDrive && !exam && timeSlots
                            ? <Col span={24}>
                                <InstructorInfoCard
                                    instructor={
                                        {
                                            ...service,
                                            testDrive: isTestDrive
                                        }
                                    }
                                    filter={{
                                        city: city_id !== undefined
                                            ? { id: city_id }
                                            : props.self?.city || service.city_services[0].city_id,
                                        all: filters.all
                                    }}
                                    type="inline-big"
                                />
                            </Col>
                            : <></>
                    }

                    <Col span={24}>
                        {
                            notification.length > 0
                                ? notification.map((notific: any) => {
                                    return <DrivingNotification
                                        notification={notific}
                                        refetch={fetchNotifications}
                                    />
                                })
                                : <></>
                        }
                        <Card className='calendar-card'>
                            {exam || testDrive
                                ? <Row>
                                    <Col span={24}>
                                        <Card>
                                            <Row gutter={[20, 20]}>
                                                <Col xl={3} sm={6} span={24}>
                                                    <Select
                                                        value={filters.city === "" ? undefined : filters.city}
                                                        style={{ width: "100%" }}
                                                        onChange={citySelectHandler}
                                                        placeholder={t('common.city')}
                                                    >
                                                        {classifiers.cities.filter((city: any) => {
                                                            return city.has_exam || testDrive;
                                                        }).map((city: any) => {
                                                            return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Col>
                                                <Col xl={3} sm={6} span={24}>
                                                    <Select
                                                        value={filters.language === "" ? undefined : filters.language}
                                                        style={{ width: "100%" }}
                                                        onChange={languageSelectHandler}
                                                        placeholder={t('common.language')}
                                                    >
                                                        {classifiers.languages.map((lang: any) => {
                                                            return <Select.Option key={lang.id} value={lang.id}>{lang.value}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Col>
                                                <Col xl={3} span={24}>
                                                    <Button onClick={eraseFilters}>{t('calendar.erase_filters')}</Button>
                                                </Col>
                                                {
                                                    filters.transmission && filters.transmission !== ""
                                                    && <Col xl={5} span={24}>
                                                        <div>
                                                            <b>{t('common.transmission.transmission')}: </b>
                                                            <Button
                                                                type="link"
                                                                onClick={setTransmission}
                                                            >
                                                                {t(`common.transmission.${filters.transmission}`)}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                : <Row>
                                    <Col span={24}>
                                        <Row justify="space-between">
                                            <div>
                                                <Button style={{ marginBottom: 10, marginRight: 10 }} onClick={showAllCities} type="primary">{filters.all ? t('calendar.show_in_your_city') : t('calendar.show_in_all_cities')}</Button>
                                                <b style={{ whiteSpace: "nowrap" }}>{t('calendar.show_free_slots_only')} <Checkbox
                                                    value={freeSlotsOnly}
                                                    onChange={(e) => setFreeSlotsOnly(e.target.checked)}
                                                /></b>
                                            </div>

                                            {allCitiesSpin ? <Spin style={{ marginLeft: 30 }} size="large" /> : <></>}
                                            <h3><b>{
                                                filters?.all
                                                    ? t('calendar.all_cities')
                                                    : classifiers.cities.filter((city: any) => city.id === city_id)[0]?.value
                                            }</b></h3>
                                        </Row>
                                    </Col>
                                </Row>}
                            <Spin
                                spinning={loading || !studentCanDrive}
                                indicator={
                                    !studentCanDrive
                                        ? <div style={{
                                            width: "auto",
                                            height: "auto",
                                            transform: "translateX(-50%)",
                                            padding: 60,
                                            borderRadius: 10,
                                            backgroundColor: "#FFFFFF",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid #1890ff"
                                        }}>
                                            <div>
                                                <StopOutlined
                                                    style={{ fontSize: 30, color: "#1890ff", display: "block", marginBottom: 20 }}
                                                />
                                                <b style={{ color: "#1890ff" }}>{!!!self?.got_theory_exam_finished && exam && !!!self?.only_driving ? t('driving.warning.theory_exam_not_passed_error') : t('driving.warning.registration_not_paid')}</b><br />
                                                <Link style={{ fontSize: 16 }} to={'/planner'}>{t('common.back_to_main')}</Link>
                                            </div>

                                        </div>
                                        : <LoadingOutlined style={{ fontSize: 30 }} spin />
                                }
                            >
                                {timeSlots ? (window.matchMedia("(max-width: 767px)").matches
                                    ? (<LessonsList
                                        student={self}
                                        fetchByPeriod={updateSlots}
                                        slots={freeSlotsOnly
                                            ? timeSlots.filter((slot: any) => {
                                                return lessons?.some((lesson: any) => lesson.lesson_id === slot.lesson_id) || !slot.lesson_id
                                            })
                                            : timeSlots}
                                        dateCell={renderDateCell}
                                        dateFormat={dateFormat}
                                    />) : (<Calendar onChange={(e: any) => updateSlots([e])} dateCellRender={renderDateCell} />)) : (<div style={{ height: "100vh" }}><Skeleton /></div>)}
                            </Spin>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
})